import { ACTION_TYPES } from "../../constant/actionTypes";

export const initialState = {
    formLoading: false,
    formValues: {},
    tableLoading: false,
    statusTable: null,
    dataTransactions: {},
    loadingIdCerc: false,
    idDetail: null,
    urs: []
};

export const CercReducer = function(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.CERC.SET_CERC:
            return {...state, ...action.payload };
        case ACTION_TYPES.CERC.WORKER.LOAD_FORM:
            return {...state, ...action.payload };
        case ACTION_TYPES.CERC.WORKER.SET_CERC:
            return {...state, ...action.payload };
        case ACTION_TYPES.CERC.WORKER.SET_APPROVE_CERC:
            return {...state, ...action.payload };        
        case ACTION_TYPES.CERC.BASIC.INIT_ID_CERC:
            return {...state, ...{loadingIdCerc: false, idDetail: null} };
        case ACTION_TYPES.CERC.BASIC.INIT:
            return {...initialState };
        default:
            return state;
    }
};