import { all } from "redux-saga/effects";
import AuthSaga from "./auth/authSagas";
import AnalitycsSaga from "./analitycs/analitycsSagas";
import ThemeSaga from "./theme/themeSagas";
import TransactionsSaga from "./transactions/transactionsSagas";
import LinkToPaySaga from './linkToPay/linktopaySagas';
import ReportsSaga from "./reports/reportsSagas";
import ReportStatusSaga from "./reportStatus/reportStatusSagas";
import ServerStatusSaga from "./status/statusSagas";
import RegisterSagas from "./register/registerSagas";
import CercSagas from './cerc/cercSagas';

export default function* rootSaga() {
  yield all([
    AuthSaga(),
    ThemeSaga(),
    AnalitycsSaga(),
    TransactionsSaga(),
    LinkToPaySaga(),
    ReportsSaga(),
    ReportStatusSaga(),
    ServerStatusSaga(),
    RegisterSagas(),
    CercSagas()
  ]);
}
