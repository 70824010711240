import { ENDPOINTS } from "../constant/endPoints";
import Axios from "axios";
var qs = require("qs");

export const fetchTable = (url, parametros, token, language) => {
  let paramsFixed = {
    ...parametros,
    language:language
  };

  return Axios.get(url, {
    headers: {
      Authorization: "Bearer ".concat(token),
      "Content-Type": "application/json",
    },
    credentials: "same-origin",
    params: paramsFixed,
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
  });
};

export const fecthCerc = (parametros, token, language) => {
  return fetchTable(`${ENDPOINTS.CERC.SEARCH_CERC}`, parametros, token, language);
};

export const fecthCercApprove = (id, token, language) => {  
  return Axios.post(`${ENDPOINTS.CERC.APPROVE_CERC}${id}`,
    {},
    {
      headers: {
        Authorization: "Bearer ".concat(token),
        "Content-Type": "application/json",
      },
      credentials: "same-origin",
    }
  );  
}

export const fetchZipDetailCercFunction = (id, token) => {  
  return Axios.get(`${ENDPOINTS.CERC.ZIP_CERC}${id}`, {
    headers: {
      Authorization: "Bearer ".concat(token),
      "Content-Type": "application/json",
    },
    responseType:'blob',
    credentials: "same-origin",
  });
}
