import { ACTION_TYPES } from "../../constant/actionTypes";

export function cercForm(sendValues) { // Acción que uso en el componente para cerc
    return {
        type: ACTION_TYPES.CERC.ASYNC.GET_CERC,
        payload: {
            sendValues
        }
    };
}

export function cercComplete(sendValues) { // Accion que uso en sagas
    return {
        type: ACTION_TYPES.CERC.WORKER.LOAD_FORM,
        payload: {
            ...sendValues
        }
    };
}

// Actions para historial de cerc
export function getTable(data) {
    return {
        type: ACTION_TYPES.CERC.ASYNC.SEARCH_CERC,
        payload: {
            data
        }
    };
}
export function setTable(data) {
  return {
    type: ACTION_TYPES.CERC.WORKER.SET_CERC,
    payload: {
      ...data
    }
  };
}
export function setInitTable() {
  return {
    type: ACTION_TYPES.CERC.BASIC.INIT
  };
}


// Aprobar ur
export function approveIdCerc(data) {
  return {
    type: ACTION_TYPES.CERC.ASYNC.APPOVE_CERC,
    payload: {data}
  };
}

export function setIdCerc(data) {
  return {
    type: ACTION_TYPES.CERC.WORKER.SET_APPROVE_CERC,
    payload: {...data}
  };
}

export function initIdCerc() {
  return {
    type: ACTION_TYPES.CERC.BASIC.INIT_ID_CERC
  }
}

export function getDetailUr(data){
  return{
    type: ACTION_TYPES.CERC.ASYNC.GET_DETAIL_UR,
    payload: {
      data
    },
  };
}
