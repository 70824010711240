import { select } from "redux-saga/effects";
import moment from 'moment';
import { all, takeLatest, put as putAction, call } from "redux-saga/effects";
import { ACTION_TYPES } from "../../constant/actionTypes";
import { fecthCerc, fecthCercApprove, fetchZipDetailCercFunction } from "../../services/cercService";
import { cercComplete } from "./cercAction";
import { getGlobalValue } from '../../../context/langContext';

export const getAuth = (state) => state.auth;

export function* SEARCH_PAYMENT({ payload }) {
  const data = payload.data
  const sendValues = {};
  sendValues.status = data.cercStatus && data.cercStatus.value ? data.cercStatus.value : null;
  sendValues.created_gt = data.dateStart ? moment(data.dateStart).format('YYYY-MM-DD') : "";
  sendValues.created_lt = data.dateEnd ? moment(data.dateEnd).format('YYYY-MM-DD') : "";
  sendValues.limit = data.limit;
  sendValues.page = data.page;
  sendValues.order = "fecha_liquidacion";
  sendValues.order_type = "desc";
  yield putAction(cercComplete({ urs: {} }));
  yield putAction(cercComplete({ formLoading: true }));
  try {
    const auth = yield select(getAuth);
    const success = yield call(fecthCerc, sendValues, auth.token);
    yield putAction(cercComplete({ urs: success.data.data.values }));
  } catch (error) { }
  yield putAction(cercComplete({ formLoading: false }));
}

export function* TABLE_TRANSACTIONS({ payload }) {
  yield putAction(cercComplete({ tableLoading: true }));
  yield putAction(cercComplete({ tableLoading: false }));
}

export function* APPROVE_ID_CERC({ payload }) {
  const connectionLanguage = getGlobalValue();
  yield putAction(cercComplete({ loadingIdCerc: true }));
  const id = payload.data;
  try {
    const auth = yield select(getAuth);
    const result = yield call(fecthCercApprove, id, auth.token, connectionLanguage);
    if (result && result.data) {
      yield putAction(cercComplete({ idDetail: result.data }));
    }
  } catch (error) {
    console.log(error);
  }
  yield putAction(cercComplete({ loadingIdCerc: false }));
}

export function* ZIP_DETAIL_UR({ payload }) {
  let id = payload.data.id;
  let reference = payload.data.reference;
  try {
    const auth = yield select(getAuth);
    const result = yield call(fetchZipDetailCercFunction, id, auth.token)
    let resObj = {
      filename: `${reference}.zip`,
      blob: result.request.response
    }
    const newBlob = new Blob([resObj.blob], { type: 'application/zip' })
    if (window.navigator && window.navigator.msSaveOrBlob) {
      window.navigator.msSaveBlob(newBlob, resObj.filename)
    } else {
      const URL = window.URL || window.MozURL || window.webkitURL || window.MSURL || window.OURL
      const anchor = document.createElement('a')
      anchor.href = URL.createObjectURL(newBlob)
      anchor.download = resObj.filename
      document.body.appendChild(anchor)
      anchor.target = '_blank'
      anchor.click()
      document.body.removeChild(anchor)
    }
  } catch (error) {
    console.log(error)
  }
}

export default function* cercSagas() {
  yield all([
    takeLatest(ACTION_TYPES.CERC.ASYNC.SEARCH_CERC, SEARCH_PAYMENT),
    takeLatest(ACTION_TYPES.CERC.ASYNC.GET_CERC, TABLE_TRANSACTIONS),
    takeLatest(ACTION_TYPES.CERC.ASYNC.APPOVE_CERC, APPROVE_ID_CERC),
    takeLatest(ACTION_TYPES.CERC.ASYNC.GET_DETAIL_UR, ZIP_DETAIL_UR),
  ]);
}
